import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, PrimaryButton, DefaultButton, ProgressIndicator, getTheme, Spinner, Dialog, DialogFooter, DialogType } from '@fluentui/react';

import PdfAllPages from '../../etax/documents/pdf/view/PdfAllPages';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

const API_URL = process.env.REACT_APP_API_URL;

//Progress Indicator Setting
const intervalDelay = 100;
const intervalIncrement = 0.01;

const WitholdingTaxConfirm = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  
  const data = location.state;
  console.log(data);

  if (data === undefined) {
    history.push('/documents/withholding-taxs');
  }

  const [percentComplete, setPercentComplete] = useState(0);

  const [url, setUrl] = useState('');

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const companyId = sessionStorage.getItem('companyId') || '';

  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const theme = getTheme();

  const handleSubmit = async (e, number) => {
    e.preventDefault();

    setLoading(true);
    const tokenResponse = await instance.acquireTokenSilent({ ...silentRequest, account: account })
  
    const duplicateDocuments = await axios.get(API_URL + `/documents?numberList=${number}`, {
                                headers: {
                                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                                  'Content-Type': 'application/json',
                                  'cid': companyId
                                },
                              })

    console.log("duplicateDocuments", duplicateDocuments)

    const isDuplicateNumberAndDocumentType = duplicateDocuments.data.some(_document => _document.type === "WITHHOLDING-TAX")

    if (isDuplicateNumberAndDocumentType) {
      setDialogConfirm((prev) => ({
        ...prev,
        isClick: true,
        header: t("billingNoteConfirm.dialogConfirmHeader"),
        message: t("billingNoteConfirm.dialogConfirmDescription"),
        onConfirm: () => handleCreateDocument(),
        onCancel: () => {
          setLoading(false);
          setDialogConfirm((prev) => ({
            ...prev,
            isClick: false,
          }));
        },
      }));
    } else {
      handleCreateDocument()
    }
  }

  const handleCreateDocument = async () => {

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/documents/withholdingtaxs/pdf',
            {
              bookNumber: data.bookNumber,
              number: data.number,
              company: data.company,
              customer: data.customer,
              orderNumber: data.orderNumber,
              formType_1: data.formType_1,
              formType_2: data.formType_2,
              formType_3: data.formType_3,
              formType_4: data.formType_4,
              formType_5: data.formType_5,
              formType_6: data.formType_6,
              formType_7: data.formType_7,
              paymentType_1: data.paymentType_1,
              paymentType_2: data.paymentType_2,
              paymentType_3: data.paymentType_3,
              paymentType_4_A: data.paymentType_4_A,
              paymentType_4_B_1_1: data.paymentType_4_B_1_1,
              paymentType_4_B_1_2: data.paymentType_4_B_1_2,
              paymentType_4_B_1_3: data.paymentType_4_B_1_3,
              paymentType_4_B_1_4: data.paymentType_4_B_1_4,
              paymentType_4_B_2_1: data.paymentType_4_B_2_1,
              paymentType_4_B_2_2: data.paymentType_4_B_2_2,
              paymentType_4_B_2_3: data.paymentType_4_B_2_3,
              paymentType_4_B_2_4: data.paymentType_4_B_2_4,
              paymentType_4_B_2_5: data.paymentType_4_B_2_5,
              paymentType_5: data.paymentType_5,
              paymentType_6: data.paymentType_6,
              amountTotal: data.amountTotal,
              vatTotal: data.vatTotal,
              vatTotalText: data.vatTotalText,
              paymentAmount: data.paymentAmount,
              payment_type_1: data.payment_type_1,
              payment_type_2: data.payment_type_2,
              payment_type_3: data.payment_type_3,
              payment_type_4: data.payment_type_4,
              otherPayment: data.otherPayment,
              date: data.date,
              dateBE: data.dateBE,
              dateCE: data.dateCE,
              signature: data.signature,
              seal: data.seal,
              referExpenseNote: data.referExpenseNote,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
                'cid': companyId
              },
            }
          )
          .then(
            (response) => {
              console.log(response);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    history.push({
      pathname: '/withholding-taxs/all',
      state: {},
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();

    history.push({
      pathname: '/documents/withholding-taxs',
      state: {
        bookNumber: data.bookNumber,
        number: data.number,
        company: data.company,
        customer: data.customer,
        orderNumber: data.orderNumber,
        formType_1: data.formType_1,
        formType_2: data.formType_2,
        formType_3: data.formType_3,
        formType_4: data.formType_4,
        formType_5: data.formType_5,
        formType_6: data.formType_6,
        formType_7: data.formType_7,
        paymentType_1: data.paymentType_1,
        paymentType_2: data.paymentType_2,
        paymentType_3: data.paymentType_3,
        paymentType_4_A: data.paymentType_4_A,
        paymentType_4_B_1_1: data.paymentType_4_B_1_1,
        paymentType_4_B_1_2: data.paymentType_4_B_1_2,
        paymentType_4_B_1_3: data.paymentType_4_B_1_3,
        paymentType_4_B_1_4: data.paymentType_4_B_1_4,
        paymentType_4_B_2_1: data.paymentType_4_B_2_1,
        paymentType_4_B_2_2: data.paymentType_4_B_2_2,
        paymentType_4_B_2_3: data.paymentType_4_B_2_3,
        paymentType_4_B_2_4: data.paymentType_4_B_2_4,
        paymentType_4_B_2_5: data.paymentType_4_B_2_5,
        paymentType_5: data.paymentType_5,
        paymentType_6: data.paymentType_6,
        amountTotal: data.amountTotal,
        vatTotal: data.vatTotal,
        vatTotalText: data.vatTotalText,
        paymentAmount: data.paymentAmount,
        payment_type_1: data.payment_type_1,
        payment_type_2: data.payment_type_2,
        payment_type_3: data.payment_type_3,
        payment_type_4: data.payment_type_4,
        otherPayment: data.otherPayment,
        date: data.date,
        dateBE: data.dateBE,
        dateCE: data.dateCE,
        backUrl: data.backUrl,
        referExpenseNote: data.referExpenseNote,
        signature: data.signature,
        seal: data.seal,
      },
    });
  };

  useEffect(() => {
    console.log('userEffect Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        try {
          axios
            .post(
              API_URL + '/documents/withholdingtaxs/pdf-preview',
              {
                bookNumber: data.bookNumber,
                number: data.number,
                company: data.company,
                customer: data.customer,
                orderNumber: data.orderNumber,
                formType_1: data.formType_1,
                formType_2: data.formType_2,
                formType_3: data.formType_3,
                formType_4: data.formType_4,
                formType_5: data.formType_5,
                formType_6: data.formType_6,
                formType_7: data.formType_7,
                paymentType_1: data.paymentType_1,
                paymentType_2: data.paymentType_2,
                paymentType_3: data.paymentType_3,
                paymentType_4_A: data.paymentType_4_A,
                paymentType_4_B_1_1: data.paymentType_4_B_1_1,
                paymentType_4_B_1_2: data.paymentType_4_B_1_2,
                paymentType_4_B_1_3: data.paymentType_4_B_1_3,
                paymentType_4_B_1_4: data.paymentType_4_B_1_4,
                paymentType_4_B_2_1: data.paymentType_4_B_2_1,
                paymentType_4_B_2_2: data.paymentType_4_B_2_2,
                paymentType_4_B_2_3: data.paymentType_4_B_2_3,
                paymentType_4_B_2_4: data.paymentType_4_B_2_4,
                paymentType_4_B_2_5: data.paymentType_4_B_2_5,
                paymentType_5: data.paymentType_5,
                paymentType_6: data.paymentType_6,
                amountTotal: data.amountTotal,
                vatTotal: data.vatTotal,
                vatTotalText: data.vatTotalText,
                paymentAmount: data.paymentAmount,
                payment_type_1: data.payment_type_1,
                payment_type_2: data.payment_type_2,
                payment_type_3: data.payment_type_3,
                payment_type_4: data.payment_type_4,
                otherPayment: data.otherPayment,
                date: data.date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                signature: data.signature,
                seal: data.seal,
                referExpenseNote: data.referExpenseNote,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                  'cid': companyId
                },
                responseType: 'blob',
              }
            )
            .then((response) => {
                setUrl(window.URL.createObjectURL(response.data));

                setPercentComplete(100);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (e) {
          console.log(e.response); // undefined
        }
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (url === '') {
      const id = setInterval(() => {
        setPercentComplete((intervalIncrement + percentComplete) % 1);
      }, intervalDelay);
      return () => {
        clearInterval(id);
      };
    }
  });

  return (
    <Stack
      vertical
      verticalAlign="start"
      style={{
        background: 'rgb(204,204,204)',
        width: '100%',
        minHeight: '100vh',
      }}
    >
      <br />
      <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
        {loading ? (
          <Spinner label={t('addContact.processing')} labelPosition="right" />
        ) : (
          <>
            <PrimaryButton text={t('withholdingTaxConfirm.confirm')} style={{ width: '125px', letterSpacing: '1px' }} onClick={(e) => handleSubmit(e, data.number)} />
            <DefaultButton text={t('withholdingTaxConfirm.edit')} onClick={handleEdit} />
          </>
        )}
      </Stack>

      <br />
      {!url ? (
        <Stack horizontal horizontalAlign="center">
          <div
            style={{
              width: '780px',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              marginLeft: '0px',
              marginRight: '0px',
              background: '#FFFFFF',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <ProgressIndicator
              label={t('withholdingTaxConfirm.processing')}
              description={t('withholdingTaxConfirm.processingMessage')}
              percentComplete={percentComplete}
              style={centerItem}
            />
            <br />
          </div>
          <br />
          <br />
        </Stack>
      ) : (
        <Stack horizontal horizontalAlign="center">
          <PdfAllPages pdf={url} />
        </Stack>
      )}

      <br />
      <br />
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { justifyContent: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('billingNoteConfirm.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('billingNoteConfirm.cancel')} />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};

export default WitholdingTaxConfirm;
