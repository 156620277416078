import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, PrimaryButton, DefaultButton, ProgressIndicator, getTheme, MessageBar, MessageBarType, Spinner, Dialog, DialogFooter, DialogType } from '@fluentui/react';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import PdfAllPages from '../../etax/documents/pdf/view/PdfAllPages';
import qrCode from 'qrcode';
import { updateProductQuantity } from '../../../helpers/fetchData/updateProductQuantity';

const API_URL = process.env.REACT_APP_API_URL;

//Progress Indicator Setting
const intervalDelay = 100;
const intervalIncrement = 0.01;

const DeliveryOrderConfirm = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const location = useLocation();
  const history = useHistory();

  const [showMessageBarDuplicateInvoiceNumber, setShowMessageBarDuplicateInvoiceNumber] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const data = location.state;
  console.log(data);

  if (data === undefined) {
    history.push('/documents/deliveryorders');
  }

  const [percentComplete, setPercentComplete] = useState(0);

  const [url, setUrl] = useState('');

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const companyId = sessionStorage.getItem('companyId') || '';

  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const theme = getTheme();

  const handleSubmit = async (e, number) => {
    e.preventDefault();

    setLoading(true);
    const tokenResponse = await instance.acquireTokenSilent({ ...silentRequest, account: account })
  
    const duplicateDocuments = await axios.get(API_URL + `/documents?numberList=${number}`, {
                                headers: {
                                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                                  'Content-Type': 'application/json',
                                  'cid': companyId
                                },
                              })

    console.log("duplicateDocuments", duplicateDocuments)

    const isDuplicateNumberAndDocumentType = duplicateDocuments.data.some(_document => _document.type === "DELIVERYORDER")

    if (isDuplicateNumberAndDocumentType) {
      setDialogConfirm((prev) => ({
        ...prev,
        isClick: true,
        header: t("deliveryordersConfirm.dialogConfirmHeader"),
        message: t("deliveryordersConfirm.dialogConfirmDescription"),
        onConfirm: () => handleCreateDocument(),
        onCancel: () => {
          setLoading(false);
          setDialogConfirm((prev) => ({
            ...prev,
            isClick: false,
          }));
        },
      }));
    } else {
      handleCreateDocument()
    }
  }

  const handleCreateDocument = async () => {
    setShowMessageBarDuplicateInvoiceNumber(false);

    if (!data.company.payment.display) {
      data.company.payment = {
        accountName: null,
        banks: null,
        note: null,
        promptpay: false,
        display: false,
        language: 'th',
      };
    }
    
    const promise = new Promise(async (resolve, reject) => {
        if (data.company.payment.paymentGateway?.key) {
            if (data.company.payment.paymentGateway.key === 'paypal') {

                instance
                    .acquireTokenSilent({ ...silentRequest, account: account })
                    .then(async (tokenResponse) => {
                        await axios
                            // .post('http://localhost:7071/api/Paypal_createInvoice', 
                            .post(API_URL + '/sources/paypal/invoices', 
                                {
                                    detail: data.company.payment.paymentGateway.data.detail,
                                    amount: data.company.payment.paymentGateway.data.amount,
                                    configuration: data.company.payment.paymentGateway.data.configuration,
                                    invoicer: data.company.payment.paymentGateway.data.invoicer,
                                    primary_recipients: data.company.payment.paymentGateway.data.primary_recipients,
                                    items: data.company.payment.paymentGateway.data.items,
                                },
                                {
                                    headers: {
                                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                                        'Content-Type': 'application/json',
                                        'cid': companyId
                                    },
                                }
                            )
                            .then(async (response) => {
                                console.log('response', response);

                                if (response.data.success) {
                                    const hrefSplit = response.data.href.split('/');
                                    const invoiceId = hrefSplit[hrefSplit.length - 1];

                                    await axios
                                    // .get('http://localhost:7071/api/Paypal_getInvoiceLink', {
                                        .post(API_URL + '/sources/paypal/invoices/' + invoiceId + '/send', 
                                        {},
                                        {
                                            headers: {
                                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                                'Content-Type': 'application/json',
                                                'cid': companyId
                                            },
                                        // params: {
                                        //     invoiceId: invoiceId,
                                        // },
                                        }
                                    )
                                    .then(async (responseLink) => {
                                        console.log('responseLink', responseLink);

                                        await qrCode
                                        .toDataURL(responseLink.data.href)
                                        .then((url) => {
                                            resolve({ success: true, url: url });
                                            // data.company.payment.paymentGateway.paymentQr = url;
                                        })
                                        .catch((err) => {
                                            console.error(err);
                                            reject(false);
                                        });
                                    });
                                } else {
                                    resolve(response.data);
                                }   
                            });
                    })
                    .catch((error) => {
                        //Acquire token silent failure, and send an interactive request
                        console.log(error);
                        reject(false);
                        instance.acquireTokenRedirect(silentRequest);
                    });

            } else if (data.company.payment.paymentGateway.key === 'stripe') {
                await axios
                    .post('http://localhost:7071/api/Stripe_getPaymentLink', 
                        {
                            // params: {
                            items: data.company.payment.paymentGateway.data.items,
                            // access_token: sessionStorage.getItem('stripe-accessToken'),
                            // },
                        },
                        {
                            headers: {
                                // Authorization: 'Bearer ' + tokenResponse.accessToken,
                            },
                        }
                    )
                    .then(async (responseLink) => {
                        console.log('responseLink', responseLink);

                        await qrCode
                            .toDataURL(responseLink.data.url)
                            .then((url) => {
                            resolve({ success: true, url: url });
                            // data.company.payment.paymentGateway.paymentQr = url;
                            })
                            .catch((err) => {
                            console.error(err);
                            });
                        });
                    }
        } else {
            resolve(false);
        }
    });

    const result = await promise;

    if (result) {
      if (result.success) {
        data.company.payment.paymentGateway.paymentQr = result.url;
      } else {
        if (result.errors.some((err) => err.issue === 'DUPLICATE_INVOICE_NUMBER')) {
          setLoading(false);
          return setShowMessageBarDuplicateInvoiceNumber(true);
        }
      }
    }

    console.log(JSON.stringify(data));

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        if (data.customer.taxId === 'N/A') {
          axios
            .post(
              API_URL + '/documents/deliveryorders/pdf',
              {
                amountTotal: data.amountTotal,
                company: data.company,
                customer: data.customer,
                date: data.date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                discount: data.discount,
                grandTotal: data.grandTotal,
                grandTotalTH: data.grandTotalTH,
                grandTotalEN: data.grandTotalEN,
                nonVatPriceTotal: data.nonVatPriceTotal,
                zeroVatPriceTotal: data.zeroVatPriceTotal,
                vatPriceTotal: data.vatPriceTotal,
                items: data.items,
                note: data.note,
                number: data.number,
                percentVat: data.percentVat,
                createdBy: data.createdBy,
                vatTotal: data.vatTotal,
                reIssue: data.reIssue,
                paymentChanged: data.paymentChanged,
                includeVat: data.includeVat,
                dueDate: data.dueDate,
                dueDateBE: data.dueDateBE,
                dueDateCE: data.dueDateCE,
                reference: data.reference,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                  'cid': companyId
                },
              }
            )
            .then(
              (response) => {
                console.log(response);
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          let contactData = {};

          if (data.customer.unstructure) {
            contactData = {
              name: data.customer.name,
              addressLineOne: data.customer.addressLineOne,
              addressLineTwo: data.customer.addressLineTwo,
              unstructure: true,
            };
          } else {
            contactData = {
              name: data.customer.name,
              buildingNumber: data.customer.buildingNumber,
              address: data.customer.address,
              streetPrefix: data.customer.streetPrefix,
              streetName: data.customer.streetName,
              provincePrefix: data.customer.provincePrefix,
              provinceCode: data.customer.provinceCode,
              provinceName: data.customer.provinceName,
              districtPrefix: data.customer.districtPrefix,
              districtCode: data.customer.districtCode,
              districtName: data.customer.districtName,
              subDistrictPrefix: data.customer.subDistrictPrefix,
              subDistrictCode: data.customer.subDistrictCode,
              subDistrictName: data.customer.subDistrictName,
              unstructure: false,
            };
          }

          contactData.countryCode = data.customer.countryCode;
          contactData.countryName = data.customer.countryName;
          contactData.postcode = data.customer.postcode;
          contactData.taxNumberType = data.customer.taxNumberType;
          contactData.taxId = data.customer.taxId;
          contactData.branchNumber = data.customer.branchNumber;
          contactData.branchText = data.customer.branchText;
          contactData.email = data.customer.email;
          contactData.phone = data.customer.phone;
          contactData.phoneCountryCode = data.customer.phoneCountryCode;
          contactData.contactName = data.customer.contactName;
          contactData.website = '';
          contactData.fax = '';
          contactData.contactPhone = '';
          contactData.contactEmail = '';
          contactData.contactNickName = '';
          contactData.contactPosition = '';
          contactData.contactDepartment = '';
          contactData.payment = {
            accountName: '',
            banks: [
              {
                bankCode: '',
                bankName: '',
                branch: '',
                typeCode: '',
                typeName: '',
                accountNumber: '',
              },
            ],
            note: '',
          };
          contactData.buyer = false;
          contactData.seller = true;
          contactData.note = '';

          if (data.contactSuggestion && Object.keys(data.contactSuggestion).length !== 0 && data.contactSuggestion.taxId === data.customer.taxId) {
            contactData.website = data.contactSuggestion.website;
            contactData.fax = data.contactSuggestion.fax;
            contactData.contactPhone = data.contactSuggestion.contactPhone;
            contactData.contactEmail = data.contactSuggestion.contactEmail;
            contactData.contactNickName = data.contactSuggestion.contactNickName;
            contactData.contactPosition = data.contactSuggestion.contactPosition;
            contactData.contactDepartment = data.contactSuggestion.contactDepartment;
            contactData.payment = data.contactSuggestion.payment;
            contactData.buyer = data.contactSuggestion.buyer;
            contactData.seller = data.contactSuggestion.seller;
            contactData.note = data.contactSuggestion.note;

            if (!data.customer.contactName) {
              contactData.contactName = data.contactSuggestion.contactName;
            }
          }

          Promise.all([
            axios.post(
              API_URL + '/documents/deliveryorders/pdf',
              {
                amountTotal: data.amountTotal,
                company: data.company,
                customer: data.customer,
                date: data.date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                discount: data.discount,
                grandTotal: data.grandTotal,
                grandTotalTH: data.grandTotalTH,
                grandTotalEN: data.grandTotalEN,
                nonVatPriceTotal: data.nonVatPriceTotal,
                zeroVatPriceTotal: data.zeroVatPriceTotal,
                vatPriceTotal: data.vatPriceTotal,
                items: data.items,
                note: data.note,
                number: data.number,
                percentVat: data.percentVat,
                createdBy: data.createdBy,
                vatTotal: data.vatTotal,
                reIssue: data.reIssue,
                paymentChanged: data.paymentChanged,
                includeVat: data.includeVat,
                dueDate: data.dueDate,
                dueDateBE: data.dueDateBE,
                dueDateCE: data.dueDateCE,
                reference: data.reference,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                  'cid': companyId
                },
              }
            ),
            axios.post(API_URL + '/contacts', contactData, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
                'cid': companyId
              },
            }),
          ]).then(
            ([responsePDF, responseContact]) => {
              console.log(responsePDF);
              console.log(responseContact);
            },
            ([errorPDF, errorContact]) => {
              console.log(errorPDF);
              console.log(errorContact);
            }
          );
        }
        
        await updateProductQuantity(data.items, tokenResponse)
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    history.push({
      pathname: '/etax/documents/all',
      state: {},
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();

    history.push({
      pathname: '/documents/deliveryorders',
      state: {
        command: 'EDIT',
        amountTotal: data.amountTotal,
        company: data.company,
        customer: data.customer,
        date: data.date,
        dateBE: data.dateBE,
        dateCE: data.dateCE,
        discount: data.discount,
        grandTotal: data.grandTotal,
        grandTotalTH: data.grandTotalTH,
        grandTotalEN: data.grandTotalEN,
        nonVatPriceTotal: data.nonVatPriceTotal,
        zeroVatPriceTotal: data.zeroVatPriceTotal,
        vatPriceTotal: data.vatPriceTotal,
        items: data.items,
        note: data.note,
        number: data.number,
        percentVat: data.percentVat,
        createdBy: data.createdBy,
        vatTotal: data.vatTotal,
        reIssue: data.reIssue,
        paymentChanged: data.paymentChanged,
        backUrl: data.backUrl,
        includeVat: data.includeVat,
        contactSuggestion: data.contactSuggestion,
        dueDate: data.dueDate,
        dueDateBE: data.dueDateBE,
        dueDateCE: data.dueDateCE,
        reference: data.reference,
      },
    });
  };

  useEffect(() => {
    console.log('userEffect Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        try {
          axios
            .post(
              API_URL + '/documents/deliveryorders/pdf-preview',
              {
                amountTotal: data.amountTotal,
                company: data.company,
                customer: data.customer,
                date: data.date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                discount: data.discount,
                grandTotal: data.grandTotal,
                grandTotalTH: data.grandTotalTH,
                grandTotalEN: data.grandTotalEN,
                nonVatPriceTotal: data.nonVatPriceTotal,
                zeroVatPriceTotal: data.zeroVatPriceTotal,
                vatPriceTotal: data.vatPriceTotal,
                items: data.items,
                note: data.note,
                number: data.number,
                percentVat: data.percentVat,
                createdBy: data.createdBy,
                vatTotal: data.vatTotal,
                reIssue: data.reIssue,
                payment: data.payment,
                includeVat: data.includeVat,
                dueDate: data.dueDate,
                dueDateBE: data.dueDateBE,
                dueDateCE: data.dueDateCE,
                reference: data.reference,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                  'cid': companyId
                },
                responseType: 'blob',
              }
            )
            .then((response) => {
              setUrl(window.URL.createObjectURL(response.data));

              setPercentComplete(100);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (e) {
          console.log(e.response); // undefined
        }
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (url === '') {
      const id = setInterval(() => {
        setPercentComplete((intervalIncrement + percentComplete) % 1);
      }, intervalDelay);
      return () => {
        clearInterval(id);
      };
    }
  });

  return (
    <Stack
      vertical
      verticalAlign="start"
      style={{
        background: 'rgb(204,204,204)',
        width: '100%',
        minHeight: '100vh',
      }}
    >
      {showMessageBarDuplicateInvoiceNumber && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarDuplicateInvoiceNumber(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('paypal.duplicateInvoiceNumber')}
        </MessageBar>
      )}
      <br />
      <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
        {loading ? (
          <Spinner label={t('addContact.processing')} labelPosition="right" />
        ) : (
          <>
            <PrimaryButton text={t('deliveryordersConfirm.confirm')} style={{ width: '125px', letterSpacing: '1px' }} onClick={(e) => handleSubmit(e, data.number)} />
            <DefaultButton text={t('deliveryordersConfirm.edit')} onClick={handleEdit} />
          </>
        )}
      </Stack>

      <br />
      {!url ? (
        <Stack horizontal horizontalAlign="center">
          <div
            style={{
              width: '780px',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              marginLeft: '0px',
              marginRight: '0px',
              background: '#FFFFFF',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <ProgressIndicator
              label={t('deliveryordersConfirm.processing')}
              description={t('deliveryordersConfirm.processingMessage')}
              percentComplete={percentComplete}
              style={centerItem}
            />
            <br />
          </div>
          <br />
          <br />
        </Stack>
      ) : (
        <Stack horizontal horizontalAlign="center">
          <PdfAllPages pdf={url} />
        </Stack>
      )}

      <br />
      <br />
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { justifyContent: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('deliveryordersConfirm.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('deliveryordersConfirm.cancel')} />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};

export default DeliveryOrderConfirm;
