import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { silentRequest } from '../../../authConfig';

import { useMsal, useAccount } from '@azure/msal-react';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  CommandBar,
  Stack,
  PrimaryButton,
  DefaultButton,
  Text,
  IconButton,
  CheckboxVisibility,
  Dialog,
  DialogType,
  DialogFooter,
  DatePicker,
} from '@fluentui/react';

// import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const onFormatDate = (date, format) => {
  if (format === "CE") {
      return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + date.getFullYear();
  } else {
      return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + (date.getFullYear() + 543);
  }
};

const formatDateText = (d) => {
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

function Peak() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t, i18n } = useTranslation();

  const [company, setCompany] = useState('');
  const [items, setItems] = useState([]);

  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  const nonVatText = '-';

  const [documents, setDocuments] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem("peak-pageSize") * 1 || 10);
  const [currentPage, setCurrentPage] = useState(1);

  const [contacts, setContacts] = useState([]);
  const [isSortedDescending, setIsSortedDescending] = useState(true);

  //CommandBars Items
  const [documentType, setDocumentType] = useState(
    sessionStorage.getItem('peak-documentType-key') ? 
    {
      key: sessionStorage.getItem('peak-documentType-key'),
      text: sessionStorage.getItem('peak-documentType-text'),
    } : 
    {
      key: '0_invoices',
      text: t('peak.allType'),
    }
  );

  const [type, setType] = useState(
    sessionStorage.getItem('peak-type-key') ?
    {
      key: sessionStorage.getItem('peak-type-key'),
      text: sessionStorage.getItem('peak-type-text'),
    } : 
    {
      key: 'RECEIPT-TAXINVOICE',
      text: t('peak.receipt-taxinvoice'),
    }
  );

  const [isLoading, setIsLoading] = useState(false);

  const isCompactMode = false;

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const companyId = sessionStorage.getItem('companyId') || '';

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log('isCreateDocument: ', isCreateDocument);
        console.log('isCreateDocumentComplete: ', isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const invoices_options = [
    {
      key: '0_invoices',
      text: t('peak.allType'),
      iconProps: { iconName: 'Page' },
      onClick: () => {
        console.log('สถานะทั้งหมด');
        setDocumentType({ key: '0_invoices', text: t('peak.allType') });

        sessionStorage.setItem("peak-documentType-key", "0_invoices");
        sessionStorage.setItem("peak-documentType-text", t('peak.allType'));

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
    {
      key: '1_invoices',
      text: t('peak.draftType'),
      iconProps: { iconName: 'Page' },
      onClick: () => {
        setDocumentType({ key: '1_invoices', text: t('peak.draftType') });

        sessionStorage.setItem("peak-documentType-key", "1_invoices");
        sessionStorage.setItem("peak-documentType-text", t('peak.draftType'));

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
    {
      key: '2_invoices',
      text: t('peak.pendingApproveType'),
      iconProps: { iconName: 'Page' },
      onClick: () => {
        setDocumentType({ key: '2_invoices', text: t('peak.pendingApproveType') });

        sessionStorage.setItem("peak-documentType-key", "2_invoices");
        sessionStorage.setItem("peak-documentType-text", t('peak.pendingApproveType'));

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
    {
      key: '3_invoices',
      text: t('peak.pendingPaymentType'),
      iconProps: { iconName: 'Page' },
      onClick: () => {
        setDocumentType({ key: '3_invoices', text: t('peak.pendingPaymentType') });

        sessionStorage.setItem("peak-documentType-key", "3_invoices");
        sessionStorage.setItem("peak-documentType-text", t('peak.pendingPaymentType'));

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
    {
      key: '4_invoices',
      text: t('peak.overdueType'),
      iconProps: { iconName: 'Page' },
      onClick: () => {
        setDocumentType({ key: '4_invoices', text: t('peak.overdueType') });

        sessionStorage.setItem("peak-documentType-key", "4_invoices");
        sessionStorage.setItem("peak-documentType-text", t('peak.overdueType'));

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
    {
      key: '5_invoices',
      text: t('peak.paidType'),
      iconProps: { iconName: 'Page' },
      onClick: () => {
        setDocumentType({ key: '5_invoices', text: t('peak.paidType') });

        sessionStorage.setItem("peak-documentType-key", "5_invoices");
        sessionStorage.setItem("peak-documentType-text", t('peak.paidType'));

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
    {
      key: '8_invoices',
      text: t('peak.voidType'),
      iconProps: { iconName: 'Page' },
      onClick: () => {
        setDocumentType({ key: '8_invoices', text: t('peak.voidType') });

        sessionStorage.setItem("peak-documentType-key", "8_invoices");
        sessionStorage.setItem("peak-documentType-text", t('peak.voidType'));

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
  ];

  const receipts_options = [
    {
      key: '0_receipts',
      text: t('peak.allType'),
      iconProps: { iconName: 'Page' },
      onClick: () => {
        console.log('สถานะทั้งหมด');
        setDocumentType({ key: '0_receipts', text: t('peak.allType') });

        sessionStorage.setItem("peak-documentType-key", "0_receipts");
        sessionStorage.setItem("peak-documentType-text", t('peak.allType'));

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
    {
      key: '1_receipts',
      text: t('peak.draftType'),
      iconProps: { iconName: 'Page' },
      onClick: () => {
        setDocumentType({ key: '1_receipts', text: t('peak.draftType') });

        sessionStorage.setItem("peak-documentType-key", "1_receipts");
        sessionStorage.setItem("peak-documentType-text", t('peak.draftType'));

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
    {
      key: '2_receipts',
      text: t('peak.pendingApproveType'),
      iconProps: { iconName: 'Page' },
      onClick: () => {
        setDocumentType({ key: '2_receipts', text: t('peak.pendingApproveType') });

        sessionStorage.setItem("peak-documentType-key", "2_receipts");
        sessionStorage.setItem("peak-documentType-text", t('peak.pendingApproveType'));

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
    {
      key: '3_receipts',
      text: t('peak.paidType2'),
      iconProps: { iconName: 'Page' },
      onClick: () => {
        setDocumentType({ key: '3_receipts', text: t('peak.paidType2') });

        sessionStorage.setItem("peak-documentType-key", "3_receipts");
        sessionStorage.setItem("peak-documentType-text", t('peak.paidType2'));

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
    {
      key: '8_receipts',
      text: t('peak.voidType'),
      iconProps: { iconName: 'Page' },
      onClick: () => {
        setDocumentType({ key: '8_receipts', text: t('peak.voidType') });

        sessionStorage.setItem("peak-documentType-key", "8_receipts");
        sessionStorage.setItem("peak-documentType-text", t('peak.voidType'));

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
  ];

  const DayPickerStrings = {
    months: [
      t('peak.months01'),
      t('peak.months02'),
      t('peak.months03'),
      t('peak.months04'),
      t('peak.months05'),
      t('peak.months06'),
      t('peak.months07'),
      t('peak.months08'),
      t('peak.months09'),
      t('peak.months10'),
      t('peak.months11'),
      t('peak.months12'),
    ],

    shortMonths: [
      t('peak.shortMonths01'),
      t('peak.shortMonths02'),
      t('peak.shortMonths03'),
      t('peak.shortMonths04'),
      t('peak.shortMonths05'),
      t('peak.shortMonths06'),
      t('peak.shortMonths07'),
      t('peak.shortMonths08'),
      t('peak.shortMonths09'),
      t('peak.shortMonths10'),
      t('peak.shortMonths11'),
      t('peak.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('peak.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('peak.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  const createDocumentFromUrl = async (item, newPeak) => {
    setIsLoading(true);

    console.log('item: ', item);

    let detail;

    if (item.documentType === 'CREDIT-NOTE') {
      detail = item.refer;
    } else if (item.documentType === 'INVOICE-TAXINVOICE') {
    //   detail = await getReceiptDetail(item.key);
      detail = await getInvoiceDetail(item.number);
    } else {
    //   detail = await getReceiptDetail(item.key);
      detail = await getReceiptDetail(item.number);
    }

    console.log("detail", detail)

    let _date = new Date(
      Number(item.issuedDate.substring(0, 4)),
      Number(item.issuedDate.substring(4, 6)) - 1,
      Number(item.issuedDate.substring(6, 8))
    );

    let _dateBE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);

    let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

    let _items = [];
    let _discount = detail.discountTotal ? Number(detail.discountTotal) : 0;

    let _includeVat = false;

    if (detail.taxStatus === 1) {
      _includeVat = true;
    }

    let _customer = {
      addressLineOne: item.customerAddress,
      addressLineTwo: '',
      email: item.customerEmail,
      name: item.customerName,
      phone: item.customerPhone,
      phoneCountryCode: '+66',
      postcode: item.customerPostcode,
      taxId: item.customerTaxId,
      taxNumberType: item._taxNumberType,
      branchText: item._branchText,
      branchNumber: item._branchNumber,
      language: 'th',
      unstructure: true,
    };

    let _nonVatPriceTotal = 0;
    let _zeroVatPriceTotal = 0;
    let _vatPriceTotal = 0;

    detail.products.forEach((item, index) => {
      if (Number(item.price) < 0) {
        const _total = Number(item.discount)
        ? Number(item.quantity) * Number(item.price) - Number(item.discount) * Number(item.quantity)
        : Number(item.quantity) * Number(item.price)

        _discount += (_total * -1);
      } else {
        let _percentVat = '';
        let _percentVatText = '';
  
        if (item.vatType === 1) {
          _percentVat = nonVat;
          _percentVatText = nonVatText;
  
          _nonVatPriceTotal = _nonVatPriceTotal + item.quantity * item.price;
        } else if (item.vatType === 2) {
          _percentVat = zeroVat;
          _percentVatText = zeroVatText;
  
          _zeroVatPriceTotal = _zeroVatPriceTotal + item.quantity * item.price;
        } else {
          _percentVat = percentVat;
          _percentVatText = percentVatText;
  
          _vatPriceTotal = _vatPriceTotal + item.quantity * item.price;
        }
  
        // Remove non ASCII in item description
  
        let _description = String(item.description.replace(/\r|\n|\t|\u0002|\u0003/g, ""));    
  
        let item_obj = {
          number: _items.length + 1 + '',
          description: _description,
          quantity: Number(item.quantity),
          unitCode: 'N/A',
          unitName: '-',
          price: Number(item.price),
          discount: Number(item.discount) ? Number(item.discount) : 0,
          percentVat: _percentVat,
          percentVatText: _percentVatText,
          total: Number(item.discount)
            ? Number(item.quantity) * Number(item.price) - Number(item.discount) * Number(item.quantity)
            : Number(item.quantity) * Number(item.price),
        };
  
        _items.push(item_obj);
  
        console.log('_nonVatPriceTotal: ', _nonVatPriceTotal);
      }
    });

    let _newNonVatPriceTotal = 0;
    let _newZeroVatPriceTotal = 0;
    let _newVatPriceTotal = 0;

    let _referDate = '';
    let _referDateBE = '';
    let _referDateCE = '';

    if (item.documentType === 'CREDIT-NOTE') {
      item.document.transactions.products.forEach((item, index) => {
        if (item.vatType === 1) {
          _newNonVatPriceTotal = _newNonVatPriceTotal + item.quantity * item.price;
        } else if (item.vatType === 2) {
          _newZeroVatPriceTotal = _newZeroVatPriceTotal + item.quantity * item.price;
        } else {
          _newVatPriceTotal = _newVatPriceTotal + item.quantity * item.price;
        }
      });

      _nonVatPriceTotal = _nonVatPriceTotal - _newNonVatPriceTotal;
      _zeroVatPriceTotal = _zeroVatPriceTotal - _newZeroVatPriceTotal;
      _vatPriceTotal = _vatPriceTotal - _newVatPriceTotal;

      _referDate = new Date(
        Number(item.refer.issuedDate.substring(0, 4)),
        Number(item.refer.issuedDate.substring(4, 6)) - 1,
        Number(item.refer.issuedDate.substring(6, 8))
      );

      _referDateBE =
        `${_referDate.getDate()}`.padStart(2, '0') + '/' + `${_referDate.getMonth() + 1}`.padStart(2, '0') + '/' + (_referDate.getFullYear() + 543);

      _referDateCE =
        `${_referDate.getDate()}`.padStart(2, '0') + '/' + `${_referDate.getMonth() + 1}`.padStart(2, '0') + '/' + _referDate.getFullYear();
    }

    let _postData = {
      createPdf: {
        fromUrl: newPeak ? item.documentUrl : item.viewUrl,
        text1: 'เอกสารนี้ไม่มีลายมือชื่อผู้มีอำนาจลงนามเนื่องจากออกด้วยระบบอิเล็กทรอนิกส์',
        text2: 'เอกสารนี้ได้จัดทำและส่งข้อมูลให้แก่กรมสรรพากรด้วยวิธีการทางอิเล็กทรอนิกส์',
      },
      amountTotal: detail.preTaxAmount,
      company: {
        ...company,
        includeVat: _includeVat,
      },
      customer: _customer,
      date: _date,
      dateBE: _dateBE,
      dateCE: _dateCE,
      discount: _discount,
      grandTotal: item.grandTotal,
      nonVatPriceTotal: _nonVatPriceTotal,
      zeroVatPriceTotal: _zeroVatPriceTotal,
      vatPriceTotal: _vatPriceTotal,
      items: _items,
      note: item.note,
      number: item.number,
      percentVat: percentVat,
      receivedBy: '',
      vatTotal: detail.vatAmount,
      reIssue: false,
      includeVat: _includeVat,
      backUrl: '/etax/sources/peak',
    };

    if (type.key === 'CREDIT-NOTE') {
      let _resonCode = '';
      let _reasonName = '';
      let _specificReason = '';

      switch (item.document.reasonType) {
        case 1:
          _resonCode = 'CDNG01';
          _reasonName = 'ลดราคาสินค้าที่ขาย (สินค้าผิดข้อกำหนดที่ตกลงกัน)';
          break;
        case 2:
          _resonCode = 'CDNS01';
          _reasonName = 'ลดราคาค่าบริการ (บริการผิดข้อกำหนดที่ตกลงกัน)';
          break;
        case 3:
          _resonCode = 'CDNG05';
          _reasonName = 'รับคืนสินค้า (สินค้าไม่ตรงตามคำพรรณนา)';
          break;
        case 4:
          _resonCode = 'CDNG01';
          _reasonName = 'ลดราคาสินค้าที่ขาย (สินค้าผิดข้อกำหนดที่ตกลงกัน)';
          break;
        case 5:
          _resonCode = 'CDNS99';
          _specificReason = 'จ่ายเงินคืนตามข้อตกลง';
          break;
        case 6:
          _resonCode = 'CDNG05';
          _reasonName = 'รับคืนสินค้า (สินค้าไม่ตรงตามคำพรรณนา)';
          break;
        case 7:
          _resonCode = 'CDNG05';
          _reasonName = 'รับคืนสินค้า (สินค้าไม่ตรงตามคำพรรณนา)';
          break;
        case 8:
          _resonCode = 'CDNS01';
          _reasonName = 'ลดราคาค่าบริการ (บริการผิดข้อกำหนดที่ตกลงกัน)';
          break;
        case 9:
          _resonCode = 'CDNS04';
          _reasonName = 'บอกเลิกสัญญาบริการ';
          break;
        case 10:
          _resonCode = 'CDNS99';
          _specificReason = 'อื่นๆ';
          break;
        default:
          break;
      }

      _postData.refer = {
        amountTotal: Math.round(item.refer.preTaxAmount * 100) / 100,
        diffAmountTotal: Math.round(item.document.preTaxAmount * 100) / 100,
        number: item.refer.code,
        date: _referDate,
        dateBE: _referDateBE,
        dateCE: _referDateCE,
        reasonCode: _resonCode,
        reasonName: _reasonName,
        specificReason: _specificReason,
        typeCode: item.document.transactionType === 102 ? 'T02' : 'T03',
        typeName: item.document.transactionType === 102 ? 'ใบแจ้งหนี้/ใบกำกับภาษี' : 'ใบเสร็จรับเงิน/ใบกำกับภาษี',
      };
    }

    console.log('Post data: ', _postData);

    let postUrl = '/etax/documents/receipts-taxinvoices/pdf-xml/url';

    switch (type.key) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml/url';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml/url';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml/url';
        break;
      default:
        break;
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            postUrl,
            {
              createPdf: {
                fromUrl: newPeak ? item.documentUrl : item.viewUrl,
                // text1: 'Digital Signed By ' + company.name,
                text1: '',
                text2: 'เอกสารนี้ได้จัดทำและส่งข้อมูลให้แก่กรมสรรพากรด้วยวิธีการทางอิเล็กทรอนิกส์',
              },
              amountTotal: detail.preTaxAmount,
              company: {
                ...company,
                includeVat: _includeVat,
              },
              customer: _customer,
              date: _date,
              dateBE: _dateBE,
              dateCE: _dateCE,
              discount: _discount,
              grandTotal: item.grandTotal,
              nonVatPriceTotal: _nonVatPriceTotal,
              zeroVatPriceTotal: _zeroVatPriceTotal,
              vatPriceTotal: _vatPriceTotal,
              items: _items,
              note: item.note,
              number: item.number,
              percentVat: percentVat,
              receivedBy: '',
              vatTotal: detail.vatAmount,
              reIssue: false,
              includeVat: _includeVat,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            }
          )
          .then(
            (response) => {
              console.log(response);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    history.push({
      pathname: '/etax/documents/all',
      state: {},
    });
  };

  const createSelectedDocumentFromUrl = async (newPeak) => {
    let _items = selection.getItems();

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        for (let i = 0; i < selectedItems.length; i++) {
          console.log('create selected document: ', selectedItems[i]);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === selectedItems[i].key) {
              console.log('item key: ', _items[j].key);
              _items[j].status = 'processing';

              console.log('items: ', _items);
              setItems([..._items]);

              console.log('item: ', _items[j]);

              let detail =
                // _items[j].documentType === 'INVOICE-TAXINVOICE' ? await getInvoiceDetail(_items[j].key) : await getReceiptDetail(_items[j].key);
                _items[j].documentType === 'INVOICE-TAXINVOICE' ? await getInvoiceDetail(_items[j].number) : await getReceiptDetail(_items[j].number);

              let _date = new Date(
                Number(detail.issuedDate.substring(0, 4)),
                Number(detail.issuedDate.substring(4, 6)) - 1,
                Number(detail.issuedDate.substring(6, 8))
              );

              let _dateBE =
                `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);

              let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

              let _productItems = [];
              let _discount = detail.discountTotal ? Number(detail.discountTotal) : 0;

              let _includeVat = false;

              if (detail.taxStatus === 1) {
                _includeVat = true;
              }

              let _customer = {
                addressLineOne: _items[j].customerAddress,
                addressLineTwo: '',
                email: _items[j].customerEmail,
                name: _items[j].customerName,
                phone: _items[j].customerPhone,
                phoneCountryCode: '+66',
                postcode: _items[j].customerPostcode,
                taxId: _items[j].customerTaxId,
                taxNumberType: _items[j]._taxNumberType,
                branchText: _items[j]._branchText,
                branchNumber: _items[j]._branchNumber,
                language: 'th',
                unstructure: true,
              };

              detail.products.forEach((item, index) => {
                if (Number(item.price) < 0) {
                  const _total = Number(item.discount)
                  ? Number(item.quantity) * Number(item.price) - Number(item.discount) * Number(item.quantity)
                  : Number(item.quantity) * Number(item.price)
          
                  _discount += (_total * -1);
                } else {
                  let _percentVat = '';
                  let _percentVatText = '';
  
                  if (item.vatType === 1) {
                    _percentVat = nonVat;
                    _percentVatText = nonVatText;
                  } else if (item.vatType === 2) {
                    _percentVat = zeroVat;
                    _percentVatText = zeroVatText;
                  } else {
                    _percentVat = percentVat;
                    _percentVatText = percentVatText;
                  }
  
                  // Remove non ASCII in item description
  
                  let _description = String(item.description.replace(/\r|\n|\t|\u0002|\u0003/g, ""));    
  
                  let item_obj = {
                    number: _productItems.length + 1 + '',
                    description: _description,
                    quantity: Number(item.quantity),
                    unitCode: 'N/A',
                    unitName: '-',
                    price: Number(item.price),
                    discount: Number(item.discount) ? Number(item.discount) : 0,
                    percentVat: _percentVat,
                    percentVatText: _percentVatText,
                    total: Number(item.discount)
                      ? Number(item.quantity) * Number(item.price) - Number(item.discount) * Number(item.quantity)
                      : Number(item.quantity) * Number(item.price),
                  };
  
                  _productItems.push(item_obj);
                }
              });

              let _vatPriceTotal = 0;
              let _zeroVatPriceTotal = 0;
              let _nonVatPriceTotal = 0;

              _productItems.forEach((item) => {
                if (item.percentVat === percentVat) {
                  _vatPriceTotal = _vatPriceTotal + item.total;
                } else if (item.percentVat === zeroVat) {
                  _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                } else if (item.percentVat === nonVat) {
                  _nonVatPriceTotal = _nonVatPriceTotal + item.total;
                }
              });

              let postUrl = '/etax/documents/receipts-taxinvoices/pdf-xml/url';

              switch (type.key) {
                case 'RECEIPT-TAXINVOICE':
                  postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml/url';
                  break;
                case 'INVOICE-TAXINVOICE':
                  postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml/url';
                  break;
                case 'RECEIPT':
                  postUrl = API_URL + '/etax/documents/receipts/pdf-xml/url';
                  break;
                default:
                  break;
              }

              const promise_PDF_XML = await new Promise(async (resolve) => {
                await axios
                  .post(
                    postUrl,
                    {
                      createPdf: {
                        fromUrl: newPeak ? _items[j].documentUrl : _items[j].viewUrl,
                        // text1: 'Digital Signed By ' + company.name,
                        text1: '',
                        text2: 'เอกสารนี้ได้จัดทำและส่งข้อมูลให้แก่กรมสรรพากรด้วยวิธีการทางอิเล็กทรอนิกส์',
                      },
                      amountTotal: detail.preTaxAmount,
                      company: {
                        ...company,
                        includeVat: _includeVat,
                      },
                      customer: _customer,
                      date: _date,
                      dateBE: _dateBE,
                      dateCE: _dateCE,
                      discount: _discount,
                      grandTotal: _items[j].grandTotal,
                      nonVatPriceTotal: _nonVatPriceTotal,
                      zeroVatPriceTotal: _zeroVatPriceTotal,
                      vatPriceTotal: _vatPriceTotal,
                      items: _productItems,
                      note: _items[j].note,
                      number: _items[j].number,
                      percentVat: percentVat,
                      receivedBy: '',
                      vatTotal: detail.vatAmount,
                      reIssue: false,
                      includeVat: _includeVat,
                    },
                    {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        'cid': companyId
                      },
                    }
                  )
                  .then(
                    async (response) => {
                      console.log('Create document response: ', response);

                      let count = 0;

                      let interval = await setInterval(async () => {
                        await axios
                          .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                            headers: {
                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                              'Content-Type': 'application/json',
                              'cid': companyId
                            },
                          })
                          .then((jobsResponse) => {
                            count += 1;
                            console.log('Jobs response: ', j, jobsResponse.data.status, count);

                            if (jobsResponse.data.status === 'complete') {
                              _items[j].status = 'successful';

                              setItems([..._items]);

                              clearInterval(interval);

                              resolve(true);
                            } else if (count >= 300) {
                              _items[j].status = 'fail';

                              setItems([..._items]);

                              clearInterval(interval);

                              resolve(false);
                            }
                          });
                      }, 1000);
                    },
                    (error) => {
                      console.log('Error Create document response: ', error);
                      _items[j].status = 'fail';
                      setItems([..._items]);
                      resolve(false);
                    }
                  );
              }); //promise

              Promise.all([promise_PDF_XML]).then((value) => {
                console.log(i, value);
              });
            } //if
          } //for j
        } //for i
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  const columns = [
    {
      key: 'column1',
      name: t('peak.number'),
      fieldName: 'number',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: isSortedDescending,
      isSortedAscending: !isSortedDescending,
      onColumnClick: (item) => {
        console.log('Sort document number!');
        setIsSortedDescending(!isSortedDescending);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.number}</span>;
      },
    },
    {
      key: 'column2',
      name: t('peak.documentDate'),
      fieldName: 'documentDate',
      minWidth: 70,
      maxWidth: 110,
      isResizable: true,
      onColumnClick: '',
      data: 'number',
      onRender: (item) => {
        return <span>{item.documentDate}</span>;
      },
    },
    {
      key: 'column3',
      name: t('peak.customerName'),
      fieldName: 'customerName',
      minWidth: 100,
      maxWidth: 140,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
    },
    {
      key: 'column4',
      name: t('peak.customerAddress'),
      fieldName: 'customerAddress',
      minWidth: 120,
      maxWidth: 140,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerAddress}</span>;
      },
    },
    {
      key: 'column5',
      name: t('peak.phone'),
      fieldName: 'name',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerPhone}</span>;
      },
    },
    {
      key: 'column6',
      name: t('peak.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
    },
    {
      key: 'column7',
      name: t('peak.view'),
      fieldName: 'view',
      minWidth: 70,
      maxWidth: 70,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return (
          <span data-selection-disabled={true}>
            <Stack horizontal>
              <div style={{ height: "100%"}}>
                <IconButton
                  onClick={() => {
                    // console.log('viewUrl: ', item.viewUrl);
                    window.open(item.viewUrl, '_blank');
                  }}
                  title={t("peak.oldPeak")}
                  iconProps={{
                    ...getFileTypeIconProps({
                      extension: 'txt',
                      size: 32,
                      imageFileType: 'svg',
                    }),
                  }}
                />

              </div>
              <div style={{ height: "100%", paddingTop: 0.5}}>
                <IconButton
                  onClick={() => {
                    // console.log('viewUrl: ', item.viewUrl);
                    window.open(item.documentUrl, '_blank');
                  }}
                  title={t("peak.newPeak")}
                  iconProps={{
                    iconName: "TextDocument",
                    styles: {root: { color: '#0078D4', fontSize: 28,  }},
                  }}
                />

              </div>

            </Stack>
          </span>
        );
      },
    },
    {
      key: 'column8',
      name: '',
      fieldName: 'command',
      minWidth: 160,
      maxWidth: 160,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('peak.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('peak.processing')} labelPosition="right" style={{ marginLeft: '-35px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('peak.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('peak.fail')}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('peak.createEtax')}
                // onClick={() => {
                //   createDocumentFromUrl(item);
                // }}
                menuProps={{
                  items: [
                    {
                      key: "oldPeak",
                      text: t("peak.oldPeak"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: () => {
                        createDocumentFromUrl(item, false)
                      },
                    },
                    {
                      key: "newPeak",
                      text: t("peak.newPeak"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: () => {
                        createDocumentFromUrl(item, true)
                      },
                    },
                  ]
                }}
              />
            </span>
          );
        }
      },
    },
  ];

  const [queryMonth, setQueryMonth] = useState(
    sessionStorage.getItem('peak-queryMonth-key')
      ? 
      {
        type: "dateRange",
        key: sessionStorage.getItem('peak-queryMonth-key'),
        text: sessionStorage.getItem('peak-queryMonth-text'),
      } : 
      { 
        type: "dateRange",
        key: formatDateText(new Date()) + ":" + formatDateText(new Date()),
        text: formatDateText(new Date()) + " - " + formatDateText(new Date()),
      }
  );

  const [fromDate, setFromDate] = useState(sessionStorage.getItem("peak-fromDate") ? new Date(sessionStorage.getItem("peak-fromDate")) : new Date());
  const [toDate, setToDate] = useState(sessionStorage.getItem("peak-toDate") ? new Date(sessionStorage.getItem("peak-toDate")) : new Date());
  const [timeRangeError, setTimeRangeError] = useState(false);

  const getFromDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);

    return formatDate(_firstDay);
};

const getToDMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);

    return formatDate(_lastDay);
};

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('');
  };

  

  const [from, setFrom] = useState(
    sessionStorage.getItem('peak-from') ? sessionStorage.getItem('peak-from') : getFromDMY(new Date().getDate(), new Date().getMonth(), new Date().getFullYear())
  );
  const [to, setTo] = useState(sessionStorage.getItem('peak-to') ? sessionStorage.getItem('peak-to') : getToDMY(new Date().getDate(), new Date().getMonth(), new Date().getFullYear()));

  const history = useHistory();

  function getDocumentTypeText(key) {
    if (key === 'RECEIPT-TAXINVOICE') return t('peak.receipt-taxinvoice');
    if (key === 'INVOICE-TAXINVOICE') return t('peak.invoice-taxinvoice');
    if (key === 'RECEIPT') return t('peak.receipt');

    if (key === '0_invoices') return t('peak.allType');
    if (key === '1_invoices') return t('peak.draftType');
    if (key === '2_invoices') return t('peak.pendingApproveType');
    if (key === '3_invoices') return t('peak.pendingPaymentType');
    if (key === '4_invoices') return t('peak.overdueType');
    if (key === '5_invoices') return t('peak.paidType');
    if (key === '8_invoices') return t('peak.voidType');

    if (key === '0_receipts') return t('peak.allType');
    if (key === '1_receipts') return t('peak.draftType');
    if (key === '2_receipts') return t('peak.pendingApproveType');
    if (key === '3_receipts') return t('peak.paidType2');
    if (key === '8_receipts') return t('peak.voidType');
  }

  function getDateText(monthObject) {
    if (monthObject.type === 'dateRange') {
      let _splitDate = monthObject.key.split(':');
      let _fromSplit = _splitDate[0].split('-');
      let _toSplit = _splitDate[1].split('-');

      if (i18n.language === 'th') {
        let _from = _fromSplit[2] + '/' + _fromSplit[1] + '/' + (Number(_fromSplit[0]) + 543);
        let _to = _toSplit[2] + '/' + _toSplit[1] + '/' + (Number(_toSplit[0]) + 543);

        return _from + ' - ' + _to;
      }
      let _from = _fromSplit[2] + '/' + _fromSplit[1] + '/' + Number(_fromSplit[0]);
      let _to = _toSplit[2] + '/' + _toSplit[1] + '/' + Number(_toSplit[0]);

      return _from + ' - ' + _to;
    }

    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('peak.thisMonth');

    let monthNames = [
      t('peak.months01'),
      t('peak.months02'),
      t('peak.months03'),
      t('peak.months04'),
      t('peak.months05'),
      t('peak.months06'),
      t('peak.months07'),
      t('peak.months08'),
      t('peak.months09'),
      t('peak.months10'),
      t('peak.months11'),
      t('peak.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: type.key,
      text: getDocumentTypeText(type.key),
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 'RECEIPT-TAXINVOICE',
            text: t('peak.receipt-taxinvoice'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('ใบเสร็จรับเงิน/ใบกำกับภาษี');
              setType({
                key: 'RECEIPT-TAXINVOICE',
                text: t('peak.receipt-taxinvoice'),
              });

              sessionStorage.setItem("peak-type-key", "RECEIPT-TAXINVOICE");
              sessionStorage.setItem("peak-type-text", t('peak.receipt-taxinvoice'));
              
              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'INVOICE-TAXINVOICE',
            text: t('peak.invoice-taxinvoice'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('ใบแจ้งหนี้/ใบกำกับภาษี');
              setType({
                key: 'INVOICE-TAXINVOICE',
                text: t('peak.invoice-taxinvoice'),
              });

              sessionStorage.setItem("peak-type-key", "INVOICE-TAXINVOICE");
              sessionStorage.setItem("peak-type-text", t('peak.invoice-taxinvoice'));
              
              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 'RECEIPT',
            text: t('peak.receipt'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('ใบเสร็จรับเงิน');
              setType({ key: 'RECEIPT', text: t('peak.receipt') });

              sessionStorage.setItem("peak-type-key", "RECEIPT");
              sessionStorage.setItem("peak-type-text", t('peak.receipt'));
              
              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
        ],
      },
    },
    {
      key: documentType.key,
      text: getDocumentTypeText(documentType.key),
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: type.key === 'INVOICE-TAXINVOICE' ? invoices_options : receipts_options,
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack>
                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                        <Text>{t("peak.fromRange")}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: "80%", justifyContent: "center" } }}>
                        <DatePicker
                            styles={{ root: { width: "90%" } }}
                            placeholder={t("peak.pleaseSelect")}
                            strings={DayPickerStrings}
                            value={fromDate}
                            maxDate={toDate}
                            formatDate={onFormatDate}
                            onSelectDate={(_date) => {
                                setFromDate(_date);

                                setTimeRangeError(false);
                                if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                            }}
                        />
                    </Stack>
                </Stack>
                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                  <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                      <Text>{t("peak.toRange")}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: "80%" } }}>
                      <DatePicker
                          styles={{ root: { width: "90%" } }}
                          placeholder={t("peak.pleaseSelect")}
                          strings={DayPickerStrings}
                          value={toDate}
                          minDate={fromDate}
                          formatDate={onFormatDate}
                          onSelectDate={(_date) => {
                              setToDate(_date);

                              setTimeRangeError(false);
                              if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                          }}
                      />
                  </Stack>
                </Stack>
                {timeRangeError ? (
                    <Stack styles={{ root: { padding: "12px 0px 0px 12px" } }}>
                        <Text styles={{ root: { fontSize: 12, color: "#A4262C" } }}>{t("peak.timeRangeError")}</Text>
                    </Stack>
                ) : null}
                <Stack horizontal horizontalAlign="end" styles={{ root: { width: "92%", height: 52, padding: "10px 0px 10px 0px" } }}>
                  <PrimaryButton
                      text={t("peak.search")}
                      onClick={() => {
                          if (!timeRangeError) {
                              setQueryMonth({
                                  type: "dateRange",
                                  key: formatDateText(fromDate) + ":" + formatDateText(toDate),
                                  text: formatDateText(fromDate) + " - " + formatDateText(toDate),
                              });

                              sessionStorage.setItem('peak-queryMonth-type', 'dateRange');
                              sessionStorage.setItem("peak-queryMonth-key", formatDateText(fromDate) + ":" + formatDateText(toDate));
                              sessionStorage.setItem("peak-queryMonth-text", formatDateText(fromDate) + " - " + formatDateText(toDate));

                              setFrom(getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                              setTo(getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                              sessionStorage.setItem("peak-from", getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                              sessionStorage.setItem("peak-to", getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                              sessionStorage.setItem("peak-fromDate", fromDate.toISOString());
                              sessionStorage.setItem("peak-toDate", toDate.toISOString());

                              setLoadDataComplete(false);
                              setItems([]);
                              setGetNewDocuments(true);

                              setCurrentPage(1);
                          }
                      }}
                      style={{ width: "125px", letterSpacing: "1px" }}
                  />
                </Stack>
              </Stack>
            </div>
          );
        },
      },
    },
    {
      key: pageSize,
      text: pageSize + ' ' + t('peak.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 10,
            text: '10 ' + t('peak.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 เอกสาร/หน้า');
              sessionStorage.setItem("peak-pageSize", 10);
              setPageSize(10);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 20,
            text: '20 ' + t('peak.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 เอกสาร/หน้า');
              sessionStorage.setItem("peak-pageSize", 20);
              setPageSize(20);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 30,
            text: '30 ' + t('peak.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 เอกสาร/หน้า');
              sessionStorage.setItem("peak-pageSize", 30);
              setPageSize(30);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 40,
            text: '40 ' + t('peak.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 เอกสาร/หน้า');
              sessionStorage.setItem("peak-pageSize", 40);
              setPageSize(40);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 50,
            text: '50 ' + t('peak.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 เอกสาร/หน้า');
              sessionStorage.setItem("peak-pageSize", 50);
              setPageSize(50);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
        ],
      },
    },
    {
      key: 'refresh',
      text: t('peak.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('peak.disconnect'),
      onClick: () => {
        console.log('Peak disconnect!');
        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('peak.disconnect'),
          message: t('peak.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
    },
  ];

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'peak',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              }
            )
            .then((response) => {
              console.log('disconnect: ', response);

              history.push({
                pathname: '/etax/sources/peak/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  async function getInvoiceDetail(id) {
    let invoiceDetail = {};

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        await axios
          .get(API_URL + '/sources/peak/invoices/' + id, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(function (response) {
            console.log('getInvoiceDetail: ', response);
            invoiceDetail = response.data.invoices;
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    return invoiceDetail;
  }

  async function getReceiptDetail(id) {
    let receiptDetail = {};

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        await axios
          .get(API_URL + '/sources/peak/receipts/' + id, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(function (response) {
            console.log(response);
            receiptDetail = response.data.receipts;
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    return receiptDetail;
  }

  useEffect(() => {
    console.log('getNewDocuments Running!!!');

    if (getNewDocuments) {
      let params = {
        status: Number(documentType.key[0]),
        from: from,
        to: to,
        isTaxInvoice: 1,
      };

      console.log('get params: ', params);

      let getUrl = API_URL + '/sources/peak/receipts';

      switch (type.key) {
        case 'RECEIPT-TAXINVOICE':
          getUrl = API_URL + '/sources/peak/receipts';
          break;
        case 'INVOICE-TAXINVOICE':
          getUrl = API_URL + '/sources/peak/invoices';
          break;
        case 'RECEIPT':
          getUrl = API_URL + '/sources/peak/receipts';
          params.isTaxInvoice = 0;
          break;
        case 'CREDIT-NOTE':
          getUrl = API_URL + '/sources/peak/creditnotes';
          break;
        default:
          break;
      }

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          await axios
            .get(getUrl, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
              params: params,
            })
            .then(
              async (response) => {
                console.log("response", response);

                let data;

                switch (type.key) {
                  case 'RECEIPT-TAXINVOICE':
                    if (response.data && response.data.receipts) {
                      data = response.data.receipts;
                    }
                    break;
                  case 'INVOICE-TAXINVOICE':
                    if (response.data && response.data.invoices) {
                      data = response.data.invoices;
                    }
                    break;
                  case 'RECEIPT':
                    if (response.data && response.data.receipts) {
                      data = response.data.receipts;
                    }
                    break;
                  case 'CREDIT-NOTE':
                    if (response.data && response.data.creditNotes) {
                      data = response.data.creditNotes;
                    }
                    break;
                  default:
                    break;
                }

                if (data && data.length > 0) {
                  if (type.key === 'CREDIT-NOTE') {
                    data.sort((a, b) => {
                      return (
                        /[A-Za-z]/.test(a.transactions.code) - /[A-Za-z]/.test(b.code) ||
                        (a.transactions.code.toUpperCase() < b.transactions.code.toUpperCase()
                          ? -1
                          : a.transactions.code.toUpperCase() > b.transactions.code.toUpperCase()
                          ? 1
                          : 0)
                      );
                    });
                  } else {
                    if (isSortedDescending) {
                      data.sort((a, b) => {
                        return (
                          /[A-Za-z]/.test(a.code) - /[A-Za-z]/.test(b.code) ||
                          (a.code.toUpperCase() < b.code.toUpperCase() ? -1 : a.code.toUpperCase() > b.code.toUpperCase() ? 1 : 0)
                        );
                      });
                    } else {
                      data.sort((a, b) => {
                        return (
                          /[A-Za-z]/.test(b.code) - /[A-Za-z]/.test(a.code) ||
                          (b.code.toUpperCase() < a.code.toUpperCase() ? -1 : b.code.toUpperCase() > a.code.toUpperCase() ? 1 : 0)
                        );
                      });
                    }
                  }

                  data.reverse();

                  console.log('invoices:', data);

                  setPageData(paginate(data.length, currentPage, pageSize, 10));
                  setDocuments(data);

                  const _docs = [];

                  let _contacts = [];

                  const [responseContacts] = await Promise.all([
                    axios
                      .get(API_URL + '/sources/peak/contacts/', {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                          'cid': companyId
                        },
                      })
                      .catch((error) => console.log(error)),
                  ]);

                  console.log("responseContacts", responseContacts)

                  _contacts = [...responseContacts.data.contacts];
                  setContacts(_contacts);

                  for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, data.length); i++) {
                    if (type.key === 'CREDIT-NOTE') {
                      let getDetailUrl;

                      if (data[i].transactionType === 102) {
                        getDetailUrl = API_URL + '/sources/peak/invoices/' + data[i].transactionId;
                      } else {
                        getDetailUrl = API_URL + '/sources/peak/receipts/' + data[i].transactionId;
                      }

                      await axios
                        .get(getDetailUrl, {
                          headers: {
                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                            'cid': companyId
                          },
                        })
                        .then(function (response) {
                          console.log('getInvoiceDetail: ', response.data);

                          let detailResponse;

                          if (response.data.invoices || response.data.receipts) {
                            if (data[i].transactionType === 102) {
                              detailResponse = response.data.invoices;
                            } else {
                              detailResponse = response.data.receipts;
                            }

                            console.log('detailResponse: ', detailResponse);

                            if (_contacts.length > 0 && _contacts.some((contact) => contact.id === detailResponse.contactId)) {
                              _contacts.forEach((contact) => {
                                data[i].document = data[i];
                                data[i].status = '';
                                data[i].refer = detailResponse;

                                if (contact.id === detailResponse.contactId) {
                                  let _contactName = contact.name
                                  if (contact.type === 2 || contact.type === 0) {
                                    _contactName = `บริษัท ${_contactName} จำกัด`
                                  } else if (contact.type === 1) {
                                    _contactName = `ห้างหุ้นส่วนจำกัด ${_contactName}`
                                  } 

                                  data[i].customerName = _contactName

                                  let _address = contact.address + ' ' + contact.subDistrict + ' ' + contact.district + ' ' + contact.province
                                  if (_address?.trim()) {
                                    data[i].customerAddress = _address
                                  } else {
                                    data[i].customerAddress = "-"
                                  }
                                  
                                  data[i].customerPhone = contact.callcenterNumber || "";
                                  data[i].customerEmail = contact.email;
                                  data[i].customerPostcode = contact.postCode ? contact.postCode.padStart(5, "0") : "00000";
                                  
                                  if (contact.taxNumber) {
                                    if (isNaN(contact.taxNumber)) {
                                      data[i].customerTaxId = String(contact.taxNumber)
                                      data[i]._taxNumberType = "CCPT"
                                      data[i]._branchNumber = ""
                                      data[i]._branchText = ""
                                    } else {
                                      data[i].customerTaxId = String(contact.taxNumber).padStart(13, "0")

                                      if (contact.branchCode) {
                                        data[i]._taxNumberType = "TXID"

                                        if (isNaN(contact.branchCode)) {
                                          data[i]._branchNumber = "00000"
                                          data[i]._branchText = "สำนักงานใหญ่"
                                        } else {
                                          data[i]._branchNumber = String(contact.branchCode).length > 5 ? String(contact.branchCode).substring(0, 5) : "00000"
                                          if (data[i]._branchNumber === "00000") {
                                            data[i]._branchText = "สำนักงานใหญ่"
                                          } else {
                                            data[i]._branchText = `สาขา ${data[i]._branchNumber}`
                                          }
                                        }
                                      } else {
                                        data[i]._taxNumberType = "NIDN"
                                        data[i]._branchNumber = ""
                                        data[i]._branchText = ""
                                      }
                                    }
                                  } else {
                                    data[i].customerTaxId = "N/A"
                                    data[i]._taxNumberType = "OTHR"
                                    data[i]._branchNumber = ""
                                    data[i]._branchText = ""
                                  }
                                }
                              });
                            }
                          }
                        });
                    } else {
                      if (_contacts.length > 0 && _contacts.some((contact) => contact.id === data[i].contactId)) {
                        _contacts.forEach((contact) => {
                          if (contact.id === data[i].contactId) {
                            let _contactName = contact.name
                            if (contact.type === 2 || contact.type === 0) {
                              _contactName = `บริษัท ${_contactName} จำกัด`
                            } else if (contact.type === 1) {
                              _contactName = `ห้างหุ้นส่วนจำกัด ${_contactName}`
                            } 

                            data[i].customerName = _contactName

                            let _address = contact.address + ' ' + contact.subDistrict + ' ' + contact.district + ' ' + contact.province
                            if (_address?.trim()) {
                              data[i].customerAddress = _address
                            } else {
                              data[i].customerAddress = "-"
                            }

                            data[i].customerPhone = contact.callcenterNumber || "";
                            data[i].customerEmail = contact.email;
                            data[i].customerPostcode = contact.postCode ? contact.postCode.padStart(5, "0") : "00000";
                            
                            data[i].type = contact.type

                            if (contact.taxNumber) {
                              if (isNaN(contact.taxNumber)) {
                                data[i].customerTaxId = String(contact.taxNumber)
                                data[i]._taxNumberType = "CCPT"
                                data[i]._branchNumber = ""
                                data[i]._branchText = ""
                              } else {
                                data[i].customerTaxId = String(contact.taxNumber).padStart(13, "0")

                                if (contact.branchCode) {
                                  data[i]._taxNumberType = "TXID"

                                  if (isNaN(contact.branchCode)) {
                                    data[i]._branchNumber = "00000"
                                    data[i]._branchText = "สำนักงานใหญ่"
                                  } else {
                                    data[i]._branchNumber = String(contact.branchCode).length > 5 ? String(contact.branchCode).substring(0, 5) : "00000"
                                    if (data[i]._branchNumber === "00000") {
                                      data[i]._branchText = "สำนักงานใหญ่"
                                    } else {
                                      data[i]._branchText = `สาขา ${data[i]._branchNumber}`
                                    }
                                  }
                                } else {
                                  data[i]._taxNumberType = "NIDN"
                                  data[i]._branchNumber = ""
                                  data[i]._branchText = ""
                                }
                              }
                            } else {
                              data[i].customerTaxId = "N/A"
                              data[i]._taxNumberType = "OTHR"
                              data[i]._branchNumber = ""
                              data[i]._branchText = ""
                            }
                          }
                          data[i].status = '';
                        });
                      }
                    }
                  }

                  for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, data.length); i++) {
                    let documentDate = '';

                    if (type.key === 'CREDIT-NOTE') {
                      documentDate =
                        data[i].transactions.issuedDate.substring(6, 8) +
                        '/' +
                        data[i].transactions.issuedDate.substring(4, 6) +
                        '/' +
                        data[i].transactions.issuedDate.substring(0, 4);

                      let _grandTotal = Math.round(data[i].netAmount * 100) / 100;

                      _docs.push({
                        key: data[i].code,
                        documentType: type.key,
                        documentDate: documentDate,
                        issuedDate: data[i].transactions.issuedDate,
                        number: data[i].transactions.code,
                        customerName: data[i].customerName,
                        grandTotal: _grandTotal,
                        customerAddress: data[i].customerAddress,
                        customerPhone: data[i].customerPhone,
                        customerEmail: data[i].customerEmail,
                        customerPostcode: data[i].customerPostcode,
                        customerTaxId: data[i].customerTaxId,
                        _branchNumber: data[i]._branchNumber,
                        _branchText: data[i]._branchText,
                        _taxNumberType: data[i]._taxNumberType,
                        note: '',
                        viewUrl: data[i].transactions.onlineViewLink,
                        status: data[i].status,
                        refer: data[i].refer,
                        document: data[i].document,
                        documentUrl: data[i].documentLink,
                        type: data[i].type,
                      });
                    } else {
                      documentDate =
                        data[i].issuedDate.substring(6, 8) + '/' + data[i].issuedDate.substring(4, 6) + '/' + data[i].issuedDate.substring(0, 4);

                      let _grandTotal = Math.round(data[i].netAmount * 100) / 100;

                      _docs.push({
                        key: data[i].code,
                        documentType: type.key,
                        documentDate: documentDate,
                        issuedDate: data[i].issuedDate,
                        number: data[i].code,
                        customerName: data[i].customerName,
                        grandTotal: _grandTotal,
                        customerAddress: data[i].customerAddress,
                        customerPhone: data[i].customerPhone,
                        customerEmail: data[i].customerEmail,
                        customerPostcode: data[i].customerPostcode,
                        customerTaxId: data[i].customerTaxId,
                        _branchNumber: data[i]._branchNumber,
                        _branchText: data[i]._branchText,
                        _taxNumberType: data[i]._taxNumberType,
                        note: '',
                        viewUrl: data[i].onlineViewLink,
                        status: data[i].status,
                        documentUrl: data[i].documentLink,
                        type: data[i].type
                      });
                    }
                  }

                  setItems(_docs);
                } else {
                  setDocuments([]);
                  setItems([]);
                }
              },
              (error) => {
                console.log(error);
              }
            );

          setCurrentPage(1);
          setLoadDataComplete(true);
          setGetNewDocuments(false);
          setIsCreateDocumentComplete(false);
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          // instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    setLoadDataComplete(false);
    console.log('Page change: ', currentPage);

    if (documents.length > 0) {
      const _docs = [];
/* 
      instance
        .acquireTokenSilent({
          ...silentRequest,
          account: account,
        })
        .then(async (tokenResponse) => {
          for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
            if (type.key === 'CREDIT-NOTE') {
              let getDetailUrl;

              if (documents[i].transactionType == 102) {
                getDetailUrl = API_URL + '/sources/peak/invoices/' + documents[i].transactionId;
              } else {
                getDetailUrl = API_URL + '/sources/peak/receipts/' + documents[i].transactionId;
              }

              await axios
                .get(getDetailUrl, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                  },
                })
                .then(async function (response) {
                  console.log('getInvoiceDetail: ', response);

                  let detailResponse;

                  if (response.data) {
                    if (documents[i].transactionType == 102) {
                      detailResponse = response.data.invoices;
                    } else {
                      detailResponse = response.data.receipts;
                    }

                    documents[i].document = documents[i];

                    documents[i].customerName = contacts.name;
                    documents[i].customerAddress = contacts.address + ' ' + contacts.subDistrict + ' ' + contacts.district + ' ' + contacts.province;
                    documents[i].customerPhone = contacts.callcenterNumber;
                    documents[i].customerEmail = contacts.email;
                    documents[i].customerPostcode = contacts.postCode;
                    documents[i].customerTaxId = contacts.taxNumber;
                    documents[i]._branchNumber = contacts.branchCode;
                    documents[i]._branchText =
                      contacts.branchCode === '00000' ? 'สำนักงานใหญ่' : !isNaN(contacts.branchCode) && contacts.branchCode !== '' ? 'สาขาที่' : '';
                    documents[i]._taxNumberType =
                      contacts.branchCode === '00000' || (!isNaN(contacts.branchCode) && contacts.branchCode !== '')
                        ? 'TXID'
                        : contacts.taxNumber !== ''
                        ? 'NIDN'
                        : '';
                    documents[i].status = '';
                    documents[i].refer = detailResponse;
                  }
                });
            } else {
              if (contacts.length > 0 && contacts.some((contact) => contact.id === documents[i].contactId)) {
                contacts.forEach((contact) => {
                  if (contact.id === documents[i].contactId) {
                    documents[i].customerName = contact.name;
                    documents[i].customerAddress = contact.address + ' ' + contact.subDistrict + ' ' + contact.district + ' ' + contact.province;
                    documents[i].customerPhone = contact.callcenterNumber;
                    documents[i].customerEmail = contact.email;
                    documents[i].customerPostcode = contact.postCode;
                    documents[i].customerTaxId = contact.taxNumber;
                    documents[i]._branchNumber = contact.branchCode;
                    documents[i]._branchText =
                      contact.branchCode === '00000' ? 'สำนักงานใหญ่' : !isNaN(contact.branchCode) && contact.branchCode !== '' ? 'สาขาที่' : '';
                    documents[i]._taxNumberType =
                      contact.branchCode === '00000' || (!isNaN(contact.branchCode) && contact.branchCode !== '')
                        ? 'TXID'
                        : contact.taxNumber !== ''
                        ? 'NIDN'
                        : '';
                    documents[i].status = '';
                  }
                });
              }
            }
          }
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
 */
        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
            
              if (contacts.length > 0 && contacts.some((contact) => contact.id === documents[i].contactId)) {
                contacts.forEach((contact) => {
                  if (contact.id === documents[i].contactId) {
                    let _contactName = contact.name
                    if (contact.type === 2 || contact.type === 0) {
                      _contactName = `บริษัท ${_contactName} จำกัด`
                    } else if (contact.type === 1) {
                      _contactName = `ห้างหุ้นส่วนจำกัด ${_contactName}`
                    } 

                    documents[i].customerName = _contactName;
                    
                    let _address = contact.address + ' ' + contact.subDistrict + ' ' + contact.district + ' ' + contact.province
                    if (_address?.trim()) {
                      documents[i].customerAddress = _address
                    } else {
                      documents[i].customerAddress = "-"
                    }

                    documents[i].customerPhone = contact.callcenterNumber || "";
                    documents[i].customerEmail = contact.email;
                    documents[i].customerPostcode = contact.postCode ? contact.postCode.padStart(5, "0") : "00000";
                    
                    documents[i].status = '';

                    if (contact.taxNumber) {
                      if (isNaN(contact.taxNumber)) {
                        documents[i].customerTaxId = String(contact.taxNumber)
                        documents[i]._taxNumberType = "CCPT"
                        documents[i]._branchNumber = ""
                        documents[i]._branchText = ""
                      } else {
                        documents[i].customerTaxId = String(contact.taxNumber).padStart(13, "0")

                        if (contact.branchCode) {
                          documents[i]._taxNumberType = "TXID"

                          if (isNaN(contact.branchCode)) {
                            documents[i]._branchNumber = "00000"
                            documents[i]._branchText = "สำนักงานใหญ่"
                          } else {
                            documents[i]._branchNumber = String(contact.branchCode).length > 5 ? String(contact.branchCode).substring(0, 5) : "00000"
                            if (documents[i]._branchNumber === "00000") {
                              documents[i]._branchText = "สำนักงานใหญ่"
                            } else {
                              documents[i]._branchText = `สาขา ${documents[i]._branchNumber}`
                            }
                          }
                        } else {
                          documents[i]._taxNumberType = "NIDN"
                          documents[i]._branchNumber = ""
                          documents[i]._branchText = ""
                        }
                      }
                    } else {
                      documents[i].customerTaxId = "N/A"
                      documents[i]._taxNumberType = "OTHR"
                      documents[i]._branchNumber = ""
                      documents[i]._branchText = ""
                    }
                  }
                });
              }
            
        }

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        let documentDate = '';

        if (type.key === 'CREDIT-NOTE') {
          documentDate =
            documents[i].transactions.issuedDate.substring(6, 8) +
            '/' +
            documents[i].transactions.issuedDate.substring(4, 6) +
            '/' +
            documents[i].transactions.issuedDate.substring(0, 4);

          let _grandTotal = Math.round(documents[i].netAmount * 100) / 100;

          _docs.push({
            key: documents[i].code,
            documentType: type.key,
            documentDate: documentDate,
            issuedDate: documents[i].transactions.issuedDate,
            number: documents[i].transactions.code,
            customerName: documents[i].customerName,
            grandTotal: _grandTotal,
            customerAddress: documents[i].customerAddress,
            customerPhone: documents[i].customerPhone,
            customerEmail: documents[i].customerEmail,
            customerPostcode: documents[i].customerPostcode,
            customerTaxId: documents[i].customerTaxId,
            _branchNumber: documents[i]._branchNumber,
            _branchText: documents[i]._branchText,
            _taxNumberType: documents[i]._taxNumberType,
            note: '',
            viewUrl: documents[i].transactions.onlineViewLink,
            status: documents[i].status,
            refer: documents[i].refer,
            document: documents[i].document,
            documentUrl: documents[i].documentLink,
            type: documents[i].type
          });
        } else {
          documentDate =
            documents[i].issuedDate.substring(6, 8) + '/' + documents[i].issuedDate.substring(4, 6) + '/' + documents[i].issuedDate.substring(0, 4);

          let _grandTotal = Math.round(documents[i].netAmount * 100) / 100;

          _docs.push({
            key: documents[i].code,
            documentType: type.key,
            documentDate: documentDate,
            issuedDate: documents[i].issuedDate,
            number: documents[i].code,
            customerName: documents[i].customerName,
            grandTotal: _grandTotal,
            customerAddress: documents[i].customerAddress,
            customerPhone: documents[i].customerPhone,
            customerEmail: documents[i].customerEmail,
            customerPostcode: documents[i].customerPostcode,
            customerTaxId: documents[i].customerTaxId,
            _branchNumber: documents[i]._branchNumber,
            _branchText: documents[i]._branchText,
            _taxNumberType: documents[i]._taxNumberType,
            note: '',
            viewUrl: documents[i].onlineViewLink,
            status: documents[i].status,
            documentUrl: documents[i].documentLink,
            type: documents[i].type
          });
        }
      }

      setItems(_docs);
      setLoadDataComplete(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
    setIsCreateDocumentComplete(false);
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  setCompany(_company);
                } else {
                  setCompany(response.data[0]);

                }
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('isCreateDocumentComplete useEffect: ', isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();
      console.log('_items: ', _items);

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setIsCreateDocumentComplete(false);

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          console.log('select item key: ', _selectedItems[i].key);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              console.log('set pending item key: ', _items[j].key);
              _items[j].status = 'pending';
              console.log('_items: ', _items);
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return (
    <div style={homeStyles}>
      {!isLoading ? (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('peak.header')}</h2>
            </center>

            <Stack>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                {selectedItems.length > 0 && (
                  <div>
                    <br />
                    <PrimaryButton
                      text={t('peak.createEtax')}
                      iconProps={{ iconName: 'Add' }}
                      // onClick={async () => {
                      //   console.log(selectedItems);
                      //   console.log('isCreateDocument: ', isCreateDocument);
                      //   setIsCreateDocument(true);
                      //   await createSelectedDocumentFromUrl(items);
                      // }}
                      menuProps={{
                        items: [
                          {
                            key: "oldPeak",
                            text: t("peak.oldPeak"),
                            iconProps: { iconName: "PageAdd" },
                            onClick: async () => {
                              // setIsCreateDocument(true);
                              setIsCreateDocument(true);
                              await createSelectedDocumentFromUrl(false);
                            },
                          },
                          {
                            key: "newPeak",
                            text: t("peak.newPeak"),
                            iconProps: { iconName: "PageAdd" },
                            onClick: async () => {
                              // setIsCreateDocument(true);
                              setIsCreateDocument(true);
                              await createSelectedDocumentFromUrl(true);
                            },
                          },
                        ]
                      }}
                    />
                    <DefaultButton
                      text={t('peak.cancel')}
                      style={{ marginLeft: '10px' }}
                      onClick={() => {
                        let _items = selection.getItems();
                        let _selectedItems = selection.getSelection();
                        console.log('selection: ', selection.getSelection());

                        for (let i = 0; i < _selectedItems.length; i++) {
                          console.log('select item key: ', _selectedItems[i].key);
                          _selectedItems[i].status = '';

                          for (let j = 0; j < _items.length; j++) {
                            if (_items[j].key === _selectedItems[i].key) {
                              console.log('item key: ', _items[j].key);
                              _items[j].status = '';
                              _selectedItems[i].status = '';
                            }
                          }
                        }

                        selection.setAllSelected(false);

                        setItems(_items);
                        console.log('selection: ', selection.getSelection());
                      }}
                    />
                  </div>
                )}

                {isCreateDocument && (
                  <div>
                    <br />
                    <Spinner label={t('peak.signProcessing')} size={SpinnerSize.large} />
                  </div>
                )}

                {isCreateDocumentComplete && (
                  <center>
                    <br />
                    <PrimaryButton
                      text={t('peak.viewAllEtax')}
                      iconProps={{ iconName: 'Documentation' }}
                      onClick={async () => {
                        console.log(selectedItems);
                        history.push({
                          pathname: '/etax/documents/all',
                          state: {},
                        });
                      }}
                    />
                  </center>
                )}
              </Stack>
            </Stack>
          </Stack>

          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '800px',
                maxWidth: '1350px',
              }}
            >
              <CommandBar items={commandBarItems} overflowItems={overflowItems} />
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selection={selection}
                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            </Stack>
          </Stack>
          <br />
          <center>
            {!(documents.length === 0 || documents.length < pageSize) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{
                  iconName: 'DoubleChevronLeft',
                }}
                previousPageIconProps={{
                  iconName: 'ChevronLeft',
                }}
                nextPageIconProps={{ iconName: 'ChevronRight' }}
                lastPageIconProps={{
                  iconName: 'DoubleChevronRight',
                }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      ) : (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner label={t('peak.loading')} labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
}

export default Peak;
